import React from 'react';

const Project = () => {
    return (
        <div>
            Project Page
        </div>
    )
}

export default Project;