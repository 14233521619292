import React, { useEffect, useState } from 'react';
import { Routes, store } from '../config';
import { Provider } from 'react-redux';

function App() {
  const [offLineStatus, setOffLineStatus] = useState(!navigator.onLine);

  function handleOfflineStatus() {
    setOffLineStatus(!navigator.onLine);
  }

  useEffect(function () {
    handleOfflineStatus();
    window.addEventListener('online', handleOfflineStatus);
    window.addEventListener('offline', handleOfflineStatus);
    return function() {
      window.addEvenListener('online', handleOfflineStatus);
      window.addEvenListener('offline', handleOfflineStatus);

      return function() {
        window.removeEvenListener('online', handleOfflineStatus);
        window.removeEvenListener('offline', handleOfflineStatus);
      }
    }
  }, [offLineStatus]);

  return (
    // {offlineStatus && console.log('tes')}
    <Provider store={store}>
      <Routes />
    </Provider>
  );
}

export default App;
